import React, { ReactNode } from 'react'
import { FlexBox } from '@vp/swan'

type ContentSectionProps = {
  children: ReactNode;
}
export const ContentSection = ({ children }: ContentSectionProps) => {
  return (
    <FlexBox
      flexDirection='column'
      as='section'
      data-testid='content-section'
      justifyContent='space-between'
    >
      {children}
    </FlexBox>
  )
}
