import { Column, GridContainer, Row } from '@vp/swan'
import { ProjectTile } from '../ProjectTile'
import { Project } from '../../../models'

interface ProjectsPreviewTilesProps {
  projects: Project[];
}

export const ProjectsPreviewTiles = ({ projects }: ProjectsPreviewTilesProps) => {
  const projectsToDisplay = projects.slice(0, 4)
  return (
    <GridContainer>
      <Row>
        {projectsToDisplay.map((project: Project) => (
          <Column key={project.id} spanXs={6} span={3}>
            <ProjectTile project={project} />
          </Column>
        ))}
      </Row>
    </GridContainer>
  )
}
