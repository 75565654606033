import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Spinner } from '@vp/swan'

type LoadingContextType = {
  setLoading: (key: string, isLoading: boolean) => void;
  resetLoading: () => void;
}

const LoadingContext = createContext<LoadingContextType>({
  setLoading: () => {},
  /**
   * This is a function allowing us to reset back to the default status of the loading overlay. This is useful for
   * cases where we are navagating to a new page and want to remove all existing loading states which will no longer be
   * valid and also add the initial loading state if it is set.
   */
  resetLoading: () => {},
})

type LoadingOverlayProps = {
  children: React.ReactNode;
  initialLoadingState?: boolean;
  initialLoadingStateTimeout?: number;
}

export const useLoadingOverlay = (key: string) => {
  const loadingContext = useContext(LoadingContext)
  if (!loadingContext) {
    throw new Error('useLoadingOverlay must be used within a LoadingOverlayProvider')
  }
  const { setLoading, resetLoading } = loadingContext

  const setIsLoading = useCallback(
    (isLoading: boolean) => {
      setLoading(key, isLoading)
    },
    [setLoading, key]
  )
  return { setIsLoading, resetLoading }
}

const INITIAL_LOADING_STATE_KEY = 'initialLoadingState'

const getInitialLoadingState = (initialLoadingState?: boolean): Record<string, boolean> => {
  if (initialLoadingState) {
    return {
      [INITIAL_LOADING_STATE_KEY]: true,
    }
  }

  return {}
}

export const LoadingOverlayProvider = ({
  initialLoadingState,
  initialLoadingStateTimeout,
  children,
}: LoadingOverlayProps) => {
  const [loadingState, setLoadingState] = useState<Record<string, boolean>>(() => {
    return getInitialLoadingState(initialLoadingState)
  })
  const timerRef = useRef<NodeJS.Timeout>()

  useEffect(() => {
    if (initialLoadingStateTimeout === undefined) {
      return
    }
    timerRef.current = setTimeout(
      () => setLoading(INITIAL_LOADING_STATE_KEY, false),
      initialLoadingStateTimeout
    )
    return () => clearTimeout(timerRef.current)
  }, [])

  const setLoading = useCallback((key: string, isLoading: boolean) => {
    setLoadingState((prevState) => {
      const newState = { ...prevState }

      // Remove the initial loading state and clear the timer if it is set
      delete newState[INITIAL_LOADING_STATE_KEY]
      clearTimeout(timerRef.current)

      if (isLoading) {
        newState[key] = isLoading
      } else {
        delete newState[key]
      }

      return newState
    })
  }, [])

  const resetTimer = useCallback(() => {
    if (initialLoadingStateTimeout === undefined) {
      return
    }
    clearTimeout(timerRef.current)

    timerRef.current = setTimeout(
      () => setLoading(INITIAL_LOADING_STATE_KEY, false),
      initialLoadingStateTimeout
    )
  }, [])

  const resetLoading = () => {
    setLoadingState(getInitialLoadingState(initialLoadingState))
    resetTimer()
  }

  const isLoading = Object.keys(loadingState).length > 0

  return (
    <LoadingContext.Provider value={{ setLoading, resetLoading }}>
      <>
        {isLoading && <Spinner accessibleText='Loading...' />}
        <div style={{ display: isLoading ? 'none' : 'block' }}>{children}</div>
      </>
    </LoadingContext.Provider>
  )
}
