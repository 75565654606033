import { Column, GridContainer, Row } from '@vp/swan'
import { useLogger, useSwanStyleKeys, useUserContext } from '@vp/ubik-context'
import { LatestProjects } from './components/LatestProjects'

export interface Props {
  greeting: string
}

export const Fragment = (props: Props) => {
  const logger = useLogger()
  const userContext = useUserContext()
  logger.log('Rendering fragment for a locale', userContext.locale)

  useSwanStyleKeys(['core'])

  return (
    <GridContainer>
      <Row>
        <Column span={12}>
          <LatestProjects />
        </Column>
      </Row>
    </GridContainer>
  )
}
