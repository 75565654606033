import { WesProject } from '../models'

export const useFetchUserProjects = (page: number, pageSize: number): any => {
  return ({
    data: [
      new WesProject({ id: '1', displayUrl: '', name: 'Pegatinas troqueladas', productKey: 'PRD-PERNKX2BI', designPreviewUrl: 'https://purcs.design.vpsvc.com/api/v1/vistaprint-prod/es-ES/product/PRD-PERNKX2BI/defaultViewImage?productVersion=20&purpose=design.studio.review&previewInstructionsUri=https%3A%2F%2Fuds.documents.cimpress.io%2Fv3%2Finstructions%3Apreview%3FdocumentUri%3Dhttps%253a%252f%252fuds.documents.cimpress.io%252fv3%252fdocuments%252ffccf799f-7433-4cfe-a4cd-ac634534e2df%252frevisions%252fe252b7de-3e62-48d4-b6e9-3245b90771d2&width=200&requestor=my-account-homepage&optionSelections=%7B%22Size%22%3A%226+x+6+cm%22%7D', lastEdited: new Date('2024-11-05T09:13:53.534519'), locale: 'US', editUrl: '/studio/?workId=6b3f3a55-708f-422f-a37c-0c4018efb915', productVersion: '1' })
    ],
    isLoading: false
  })
}
