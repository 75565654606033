import React from 'react'
import { ContentSection, ContentSectionContent, ContentSectionHeader, ContentSectionLargeHeading } from '../../ContentSection'
import { Link } from '@vp/swan'

interface ProjectsPreviewLayoutProps {
  children: React.ReactNode;
}

export const ProjectsPreviewLayout = ({ children }: ProjectsPreviewLayoutProps) => {
  return (
    <ContentSection>
      <ContentSectionHeader>
        <ContentSectionLargeHeading>Latest projects</ContentSectionLargeHeading>
        <Link
          href='#'
        >
          projectsViewAll
        </Link>
      </ContentSectionHeader>
      <ContentSectionContent>{children}</ContentSectionContent>
    </ContentSection>
  )
}
