import React from 'react'
import { Button, Link, Span } from '@vp/swan'
import { Project } from '../../../models'
import { ProductTile } from '../../ProductTile'

interface ProjectTileProps {
  project: Project;
}

export const ProjectTile = ({ project }: ProjectTileProps) => {
  const projectTileCta = (
    <Button
      skin='primary'
      width='full-width'
      render={(props) => (
        <Link
          href={project.editUrl || ''}
          className={props.className}
        >
          {props.children}
        </Link>
      )}
    >
      edit
    </Button>
  )

  return (
    <ProductTile
      imageSrc={project.previewImageUrl || ''}
      imageAlt='project_thumbnail_image_alt_text'
      tileHeading={project.name}
      tileDescription={
        <Span>
          {project.lastEdited.toLocaleDateString()}
        </Span>
      }
      tileCta={projectTileCta}
    />
  )
}
