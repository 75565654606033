import React from 'react'
import {
  ResponsiveImage,
  StandardTile,
  StandardTileContents,
  StandardTileDescription,
  StandardTileFooter,
  StandardTileImage,
  StandardTileName,
} from '@vp/swan'

import './product-tile.scss'

export type ProductTileProps = {
  layout?: 'vertical' | 'horizontal' | null;
  imageSrc?: string;
  imageAlt?: string;
  tileHeading: string;
  tileDescription: React.ReactNode;
  tileCta: React.ReactNode;
}

export const ProductTile = ({
  layout = 'vertical',
  imageSrc,
  imageAlt,
  tileHeading,
  tileDescription,
  tileCta,
}: ProductTileProps) => {
  const imagePaddingXs = 4
  const imagePaddingMd = 5
  return (
    <StandardTile skin='product' layout={layout} imageWidth='full-bleed'>
      <StandardTileImage>
        <ResponsiveImage
          paddingTop={{ xs: imagePaddingXs, md: imagePaddingMd }}
          paddingLeft={{ xs: imagePaddingXs, md: imagePaddingMd }}
          paddingRight={{ xs: imagePaddingXs, md: imagePaddingMd }}
          src={imageSrc}
          alt={imageAlt || ''}
        />
      </StandardTileImage>
      <StandardTileContents>
        <StandardTileName className='project-heading'>{tileHeading}</StandardTileName>
        <StandardTileDescription>{tileDescription}</StandardTileDescription>
      </StandardTileContents>
      <StandardTileFooter>{tileCta}</StandardTileFooter>
    </StandardTile>
  )
}
