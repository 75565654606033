import React, { ReactNode, useEffect } from 'react'
import { ProjectsPreviewTiles } from './ProjectsPreviewTiles'
import { useFetchUserProjects } from '../../hooks'
import { Card, Span } from '@vp/swan'
import { useLoadingOverlay } from '../LoadingOverlayProvider'
import { ProjectsPreviewLayout } from './ProjectsPreviewLayout'

export const LatestProjects = () => {
  return (
    <>
      <ProjectsPreview />
    </>
  )
}

const ProjectsPreview = () => {
  const { isLoading, data: projects, error, refetch } = useFetchUserProjects(0, 4)

  useEffect(() => {
    if (error) {
      reportError(error as Error)
    }
  }, [error])

  const { setIsLoading } = useLoadingOverlay('latestProjectsPreview')
  useEffect(() => {
    setIsLoading(isLoading)
    return () => {
      setIsLoading(false)
    }
  }, [isLoading])

  if (isLoading) {
    return <></>
  }

  const hasError = !!error || projects === undefined
  if (hasError) {
    return <ErrorPreviewSection onRetry={refetch} />
  }

  return (
    <ProjectsPreviewLayout>
      {projects.length === 0 && (
        <NoPreviousProjectsCard>
          <Span>..preview..</Span>
        </NoPreviousProjectsCard>
      )}
      <ProjectsPreviewTiles projects={projects} />
    </ProjectsPreviewLayout>
  )
}

const ErrorPreviewSection = ({ onRetry }: { onRetry: () => void }) => {
  return (
    <Card>
      <Span>No projects</Span>
    </Card>
  )
}

const NoPreviousProjectsCard = ({ children }: { children: ReactNode }) => {
  return (
    <Span>
      No previous projects
    </Span>
  )
}
