import { ReactNode } from 'react'
import { FlexBox } from '@vp/swan'

type ContentSectionHeaderProps = {
  children: ReactNode;
}

export const ContentSectionHeader = ({ children }: ContentSectionHeaderProps) => {
  return (
    <FlexBox
      justifyContent='space-between'
      alignItems='center'
      data-testid='content-section-header'
    >
      {children}
    </FlexBox>
  )
}
